import React, { useEffect, useState, useRef } from 'react';
import * as Styled from './iframeContainerStyles';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';

const IframeContainer = ({ url }) => {
  const [iframeHeight, setIframeHeight] = useState('100vh');
  const iframeRef = useRef(null);

  const resizeIFrameToFitContent = (iframe) => {
    if (iframe && iframe.contentWindow) {
      iframe.style.width = `${iframe.contentWindow.document.body.scrollWidth}px`;
      iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    }
  };

  // useEffect(() => {
  //   const iFrame = iframeRef.current;
  //   resizeIFrameToFitContent(iFrame);
  // }, []);

  const optionsMainStyle = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => (
        <Styled.Body>
          <Styled.Iframe
            id="vizi_mjp"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox, allow-forms"
            style={{ position: 'relative', top: '-120px', width: '100%', border: 'none' }}
            src={node?.data?.uri}
            frameborder="0"
          ></Styled.Iframe>
        </Styled.Body>
      ),
    },
  };
  function getIframeHeight(iframe) {
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      console.error('Iframe document not available.');
      return 0;
    }
    return Math.max(iframeDocument.body.scrollHeight, iframeDocument.documentElement.scrollHeight);
  }

  return (
    <div>
      <Styled.Body $heightframe={iframeHeight}>
        <Styled.Iframe
          id="vizi_mjp"
          ref={iframeRef}
          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox, allow-forms"
          style={{ position: 'relative', width: '100%', border: 'none', overflow: 'hidden', height: '500vh' }}
          src={url}
          frameborder="0"
        ></Styled.Iframe>
      </Styled.Body>
    </div>
  );
};

export default IframeContainer;
