import styled from 'styled-components';

export const Iframe = styled.iframe`
  min-width: 100%;
  border: none;
  position: relative;
  // top: -100px;
  overflow:hidden;
  clip-path: inset(100px 20px 0 0);
`;

export const Body = styled.div`
position: relative;
height: 350vh;
overflow: hidden;

//   margin: auto;
// //   overflow-x: scroll; 
// //   overflow-y: scroll;
// //   -webkit-overflow-scrolling:touch;
//   height: 650px;
//   width: 90%;

//   @media (max-width: 1199px) {
//     padding: 0px 50px 20px 50px;
//   }
//   @media (max-width: 767px) {
//     padding: 0px 35px 0 35px;
//     height: 550px;
//     width: 100%;
//   }
`;
